@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "colors";
@import "feather";
@import "fonts";


/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
} */

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

p {
  margin: 0.8rem 0;
}

@layer base {
  html {
    font-family: "Proxima Nova", system-ui, sans-serif;
  }
}

@layer utilities {
  .overflow-clip-margin {
    overflow-clip-margin: content-box 5px;
  }
}
.pattern {
  position: relative;
  overflow-x: hidden;
}
.pattern::before {
  content: ' ';
  display: block;
  position: absolute;
}

.pattern-binary-artwork::before {
  background-image: url("/assets/patterns/Binary_Artwork.svg");
  background-size: 1205px 495px;
  background-position: 75% center;
  opacity: 0.2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.pattern-teal::before {
  /* Teal/ Lightest Teal */
  background: rgb(var(--color-teal-lightest));
  border-radius: 36.6576px;
  transform: matrix(1, 0, -0.4, 0.92, 0, 0);
  z-index: -1;
}

.pattern-pink::before {
  /* Pink/ Lightest Pink */
  background: rgb(var(--color-pink-lightest));
  border-radius: 36.6576px;
  transform: matrix(1, 0, -0.4, 0.92, 0, 0);
  width: 75%;
  height:90%;
  margin-left: 4.5rem
}

.docs-bg-header {
  background-size:cover;
  background-position: center;
}

.docs-bg-header.front .product-logo {
  mask-image: url('/assets/printed-document.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 164px;
  background-color: white;
}

.docs-bg-mc {
  background-image: url("/assets/headers/MarketingCloud.svg");
}

.docs-bg-dc {
  background-image: url("/assets/headers/DrupalCloud.svg");
}

.docs-bg-oc {
  background-image: url("/assets/headers/OptimizeCloud.svg");
}

.docs-bg-dc-light {
  background-image: url("/assets/headers/DrupalCloudLight.svg");
}

.docs-bg-mc-light {
  background-image: url("/assets/headers/MarketingCloudLight.svg");
}

.tape-mc {
  background-image: url("/assets/Tape-MC.svg");
  background-repeat: no-repeat;
  background-position-y: bottom;
}

.tape-dc {
  background-image: url("/assets/Tape-DC.svg");
  background-repeat: no-repeat;
  background-position-y: bottom;
}

.quick-links a {
  background: white url(/assets/droplet-badge.svg) no-repeat;
  background-position: 1rem;
}

mark, .highlight {
  background-color: rgb(var(--color-yellow-lighter));
  border-radius: 0.15em;
  padding: 0 .1em;
  margin: 0 -.1em;
}

.diffmod, .diffins, .mod, .diffdel {
  padding: 0 .1em;
  margin: 0 -.1em;
  border-radius: 0.15em;
}

h1, h2, h3, h4, h5, h6, p, div, article, address, aside, blockquote, canvas, dd, dl, dt, fieldset, figcaption,
figure, footer, form, header, li, main, nav, noscript, ol, ul, pre, section, table, video {
  > {
    .diffins, .diffdel {
      display: block
    }
  }
}

ins {
  text-decoration: none;
}

ins.diffmod, ins.mod, ins.diffins {
  @apply bg-teal-400;

  &:hover {
    @apply bg-teal-300 cursor-copy;
  }
}

ins.diffmod img, ins.mod img, ins.diffins img {
  opacity: 50%;
  padding-left: 5px;
}

del.diffmod, del.diffdel {
  @apply bg-orange-400;

  &:hover {
    @apply cursor-not-allowed bg-orange-300;
  }
}

del.diffmod img, del.diffdel img {
  border: solid 3px rgb(var(--color-orange-lighter));
}

a[target^="_blank"]::after, 
.external.reference::after {
  content: "";
  background-color: rgb(var(--color-navy-base));
  -webkit-mask-image: url("/assets/external-link.svg");
  mask-image: url("/assets/external-link.svg");
  mask-size: 70%;
  mask-repeat: no-repeat;
  mask-position: center;
  width: 1.125rem; /* 18px */
  height: 1rem; /* 22px */
  margin-left: 1%;
}

.ck a[target^="_blank"]::after,
.external.reference::after {
  display: inline-block;
}

/* Don't show icon in footer */
footer a[target^="_blank"]::after, body div a.no-ext::after {
  mask-image: none;
  background-color: transparent;
  width: auto;
  content: none;
  height: auto;
  margin-left: 0;
  display: contents;
}

a[target^="_blank"][class*="hover:text-white"]:hover::after {
  background-color: white;
}

a[target^="_blank"][class*=" text-white"]::after {
  background-color: white;
}

.wysiwyg-only {
  display:none;
}

.toc {
  max-height: calc(100vh - 50px);
}
